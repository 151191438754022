import React, { useEffect, useState } from "react"
import  { Button, Dialog, DialogActions, DialogTitle, Input, makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";
import { i18n } from "../../../translate/i18n";
import api from "../../../services/api";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
    center: {
        display: "flex",
        justifyContent: "center",
        paddingLeft: "20px",
        paddingRight: "20px",
    },
	btnWrapper: {
		position: "relative",
	}
}));

export default function EditAudioName({ open, handleClose, audio }) {
    const classes = useStyles();
    const [name, setName] = useState(audio?.mediaName)

    useEffect(() => {
        if(audio) {
            setName(audio.mediaName.replace(".mp3", ""))
        }
    },[audio])

    const handleSave = async () => {
        if(!name) {
            toast.error("Insira o novo nome")
            return
        }

        const response = await api.put(`/audios/${audio?.id}`, {
            name: name + ".mp3"
        })
        if (response.status === 200) {
            toast.success("Nome alterado com sucesso");
            handleClose()
        }
    }

    return (
        <div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					Editar nome do áudio
				</DialogTitle>


                <div className={classes.center}>
                    <Input
                        placeholder="Defina um nome para o áudio"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        style={{width: "100%"}}
                        onKeyDown={({ key }) => {
                            if(key === "Enter") {
                                handleSave()
                            }
                        }}
                    />
                </div>
                <DialogActions>
					<Button
                        onClick={handleClose}
                        color="secondary"
                        variant="outlined"
					>
					    {i18n.t("scheduleModal.buttons.cancel")}
					</Button>
					<Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.btnWrapper}
                        onClick={handleSave}

					>
					    Salvar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
    )
}