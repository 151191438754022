import React, { useState, useEffect, useReducer } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"

import MainContainer from "../../components/MainContainer"
import MainHeader from "../../components/MainHeader"
import Title from "../../components/Title"
import SubscriptionModal from "../../components/SubscriptionModal"
import api from "../../services/api"

import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"

import Typography from "@material-ui/core/Typography"

import { toast } from "react-toastify"

import config from "../../config/config.json"

const useStyles = makeStyles({
    card: {
        maxWidth: 300,
        padding: "12px 8px",
        marginLeft: "auto",
        marginRight: "auto",
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    titleContainer: {
        textAlign: "center",
        width: "100%",
        marginTop: "1rem",
        marginBottom: "2.225rem",
    },
})

const Invoices = () => {
    const classes = useStyles()
    const bull = <span className={classes.bullet}>•</span>
    const [subscription, setSubscription] = useState(null)

    const [loading, setLoading] = useState(false)

    const [storagePlans, setStoragePlans] = React.useState([])
    const [selectedContactId, setSelectedContactId] = useState(null)
    const [contactModalOpen, setContactModalOpen] = useState(false)

    const getSubscription = async () => {
        setLoading(true)
        try {
            const response = await api.get("/subscription")

            if (response.status === 200) {
                if (response.data.subscription) {
                    setSubscription({
                        ...response.data.subscription,
                        plan: response.data.plan,
                    })
                }
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getSubscription()
    }, [])

    const handleCancel = async () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Tem certeza que deseja cancelar sua assinatura?")) {
            const response = await api.put(`/subscription/delete`, {
                id: subscription.id,
            })
            if (response.status === 200) {
                toast.warning(
                    `Você cancelou sua assinatura. Considere escolher um novo plano para continuar utilizando o ${config.system.name}`
                )
                setSubscription(null)
            }
        }
    }

    return (
        <MainContainer>
            <SubscriptionModal
                open={contactModalOpen}
                onClose={() => {
                    setContactModalOpen(false)
                    getSubscription()
                }}
                aria-labelledby="form-dialog-title"
                Invoice={storagePlans}
                contactId={selectedContactId}
            ></SubscriptionModal>

            <MainHeader>
                <div className={classes.titleContainer}>
                    <Title>Assinatura</Title>
                </div>
            </MainHeader>

            {!!subscription && (
                <Card className={classes.card} variant="outlined">
                    <CardContent>
                        <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                            align="center"
                        >
                            {subscription.status === "waitingPayment"
                                ? "Pagamento pendente"
                                : subscription.status === "waitingPayment"
                                ? "Aguardando pagamento"
                                : "Sua assinatura"}
                        </Typography>
                        <Typography variant="h5" component="h2" align="center">
                            {subscription.plan.name}
                        </Typography>
                        <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                            align="center"
                            style={{
                                marginBottom: "1rem",
                                fontSize: "0.75rem",
                            }}
                        >
                            Cód: {subscription.id}
                        </Typography>
                        <Typography
                            variant="body2"
                            component="p"
                            align="center"
                        >
                            {subscription.plan.users} usuários
                        </Typography>
                        <Typography
                            variant="body2"
                            component="p"
                            align="center"
                        >
                            {subscription.plan.connections} conexões
                        </Typography>
                        <Typography
                            variant="body2"
                            component="p"
                            align="center"
                        >
                            {subscription.plan.queues} filas
                        </Typography>
                        <Typography
                            variant="h5"
                            component="h2"
                            align="center"
                            style={{ marginTop: "1rem" }}
                        >
                            R$ {subscription.plan.value}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                            }}
                        >
                            {subscription.status === "waitingPayment" &&
                                !!subscription.providerSubscriptionId && (
                                    <Button
                                        size="medium"
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                        href={subscription.paymentUrl}
                                        target="_blank"
                                    >
                                        Pagar e ativar assinatura
                                    </Button>
                                )}

                            <Button
                                size="medium"
                                variant="contained"
                                fullWidth
                                color="secondary"
                                onClick={handleCancel}
                                target="_blank"
                            >
                                Cancelar assinatura
                            </Button>
                        </div>
                    </CardActions>
                </Card>
            )}
            {!subscription && (
                <Card className={classes.card} variant="outlined">
                    <CardContent>
                        <Typography
                            variant="h5"
                            component="h2"
                            align="center"
                            style={{ marginBottom: "1rem" }}
                        >
                            Assine um plano para continuar
                        </Typography>

                        <Typography
                            className={classes.pos}
                            color="textSecondary"
                            align="center"
                        >
                            O pagamento é feito via cartão de crédito com total
                            segurança e pode levar alguns dias para ser liberado
                            dependendo da administradora do seu cartão.
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button
                            size="large"
                            variant="contained"
                            fullWidth
                            color="primary"
                            onClick={() => setContactModalOpen(true)}
                        >
                            Ver planos
                        </Button>
                    </CardActions>
                </Card>
            )}
        </MainContainer>
    )
}

export default Invoices
