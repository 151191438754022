import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { has, isArray } from "lodash";

import { toast } from "react-toastify";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { socketConnection } from "../../services/socket";
import moment from "moment";

const useAuth = () => {
    const history = useHistory();
    const [isAuth, setIsAuth] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});

    api.interceptors.request.use(
        config => {
            const token = localStorage.getItem("token");
            if (token) {
                config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
                setIsAuth(true);
            }
            return config;
        },
        error => {
            Promise.reject(error);
        }
    );

    api.interceptors.response.use(
        response => {
            return response;
        },
        async error => {
            const originalRequest = error.config;
            if (error?.response?.status === 403 && !originalRequest._retry) {
                originalRequest._retry = true;

                const { data } = await api.post("/auth/refresh_token");
                if (data) {
                    localStorage.setItem("token", JSON.stringify(data.token));
                    api.defaults.headers.Authorization = `Bearer ${data.token}`;
                }
                return api(originalRequest);
            }
            if (error?.response?.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("companyId");
                api.defaults.headers.Authorization = undefined;
                setIsAuth(false);
            }
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        const token = localStorage.getItem("token");
        (async () => {
            if (token) {
                try {
                    const { data } = await api.post("/auth/refresh_token");
                    api.defaults.headers.Authorization = `Bearer ${data.token}`;
                    setIsAuth(true);
                    setUser(data.user);
                } catch (err) {
                    toastError(err);
                }
            }
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        const companyId = localStorage.getItem("companyId");
        const socket = socketConnection({ companyId });

        socket.on(`company-${companyId}-user`, data => {
            if (data.action === "update" && data.user.id === user.id) {
                setUser(data.user);
            }
        });

        return () => {
            socket.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const handleLogin = async userData => {
        setLoading(true);

        try {
            const { data } = await api.post("/auth/login", userData);
            const {
                user: { companyId, id, company }
            } = data;

            if (has(company, "settings") && isArray(company.settings)) {
                const setting = company.settings.find(
                    s => s.key === "campaignsEnabled"
                );
                if (setting && setting.value === "true") {
                    localStorage.setItem("cshow", null); //regra pra exibir campanhas
                }
            }

            moment.locale("pt-br");
            const dueDate = data.user.company.dueDate;
            const hoje = moment(moment()).format("DD/MM/yyyy");
            const vencimento = moment(dueDate).format("DD/MM/yyyy");

            var diasVenc = vencimento.valueOf() - hoje.valueOf();
            console.log("🚀 Console Log : diasVenc", diasVenc);

            localStorage.setItem("token", JSON.stringify(data.token));
            localStorage.setItem("companyId", companyId);
            localStorage.setItem("userId", id);
            localStorage.setItem("companyDueDate", vencimento);
            localStorage.setItem("dueDate", dueDate);
            api.defaults.headers.Authorization = `Bearer ${data.token}`;
            setUser(data.user);
            setIsAuth(true);
            toast.success(i18n.t("auth.toasts.success"));

            history.push("/tickets");
            setLoading(false);

            //quebra linha
        } catch (err) {
            toastError(err);
            setLoading(false);
        }
    };

    const handleLogout = async () => {
        setLoading(true);

        try {
            await api.delete("/auth/logout");
            setIsAuth(false);
            setUser({});
            localStorage.removeItem("token");
            localStorage.removeItem("companyId");
            localStorage.removeItem("userId");
            localStorage.removeItem("cshow");
            api.defaults.headers.Authorization = undefined;
            setLoading(false);
            history.push("/login");
        } catch (err) {
            toastError(err);
            setLoading(false);
        }
    };

    const getCurrentUserInfo = async () => {
        try {
            const { data } = await api.get("/auth/me");
            return data;
        } catch (err) {
            toastError(err);
        }
    };

    return {
        isAuth,
        user,
        loading,
        handleLogin,
        handleLogout,
        getCurrentUserInfo
    };
};

export default useAuth;
