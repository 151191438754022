import React, { useState } from "react";

import * as Yup from "yup";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { i18n } from "../../translate/i18n";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import RecordingTimer from "../MessageInput/RecordingTimer"
import api from "../../services/api";
import toastError from "../../errors/toastError";
import MicIcon from "@material-ui/icons/Mic";
import MicRecorder from "mic-recorder-to-mp3";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { Input } from "@material-ui/core";
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
    uploadButtonHolder: {
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    buttonIcon: {
        display: "flex",
        justifyContent: "center",
    },
    sendMessageIcons: {
		marginLeft: "auto",
		marginRight: "auto",
	},
    center: {
        display: "flex",
        justifyContent: "center"
    },
    recorderWrapper: {
		display: "flex",
		alignItems: "center",
		alignContent: "middle",
	},

    cancelAudioIcon: {
		color: "red",
	},

	sendAudioIcon: {
		color: "green",
	},
}));



const AudioModal = ({ open, onClose }) => {
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const [recording, setRecording] = useState(false);
    const [player, setPlayer] = useState(null)
    const [fileToUpload, setFileToUpload] = useState(null)
    const [name, setName] = useState("")


	const handleClose = () => {
		onClose();
        setPlayer(null)
        setFileToUpload(null)
        setRecording(false)
        setName("")
	};

    const handleStartRecording = async () => {
		setLoading(true);
        setPlayer(null)
		try {
			await navigator.mediaDevices.getUserMedia({ audio: true });
			await Mp3Recorder.start();
			setRecording(true);
			setLoading(false);
		} catch (err) {
			toastError(err);
			setLoading(false);
		}
	};

    const handleCancelAudio = async () => {
		try {
			await Mp3Recorder.stop().getMp3();
			setRecording(false);
		} catch (err) {
			toastError(err);
		}
	};

    const handleFinishRecord = async () => {
		setLoading(true);
		try {
			const [buffer, blob] = await Mp3Recorder.stop().getMp3();
			if (blob.size < 10000) {
				setLoading(false);
				setRecording(false);
				return;
			}

            const file = new File(buffer, 'me-at-thevoice.mp3', {
                type: blob.type,
                lastModified: Date.now()
            });

            const rec = new Audio(URL.createObjectURL(file));
            setPlayer(rec)

            setFileToUpload(blob)

		} catch (err) {
			toastError(err);
		}

		setRecording(false);
		setLoading(false);
	};

    const handleUpload = async () => {
        try {
            if(!name) {
                toast.error("Informe um nome para o áudio")
                return
            }
            const audio = await api.post("/audios")
            const formData = new FormData();
			const filename = `${name}.mp3`;
			formData.append("file", fileToUpload, filename);
			formData.append("body", filename);
			formData.append("fromMe", true);

			await api.post(`/audios/${audio.data.id}/media-upload`, formData);
            toast.success("Áudio gravado com sucesso")
            handleClose()
        } catch (error) {
            console.log(error)
            toastError("Erro ao salvar áudio")
        }
    }


	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					Gravar áudio
				</DialogTitle>


                <div className={classes.center}>
                {
                player &&
                <div className={classes.recorderWrapper}>
                    <Input placeholder="Defina um nome para o áudio" value={name} onChange={e => setName(e.target.value)} />
                    <IconButton
                        aria-label="showRecorder"
                        component="span"
                        disabled={loading}
                        onClick={() => player.play() }>
                                 <PlayCircleOutlineIcon color="primary" />
                    </IconButton>
                    Ouvir
                </div>
            }
                </div>

				<div className={classes.center}>
                    {recording ? (
						<div className={classes.recorderWrapper}>
							<IconButton
								aria-label="cancelRecording"
								component="span"
								fontSize="large"
								disabled={loading}
								onClick={handleCancelAudio}
							>
								<HighlightOffIcon className={classes.cancelAudioIcon} />
							</IconButton>
							{loading ? (
								<div>
									<CircularProgress className={classes.audioLoading} />
								</div>
							) : (
								<RecordingTimer />
							)}

							<IconButton
								aria-label="sendRecordedAudio"
								component="span"
								onClick={handleFinishRecord}
								disabled={loading}
							>
								<CheckCircleOutlineIcon className={classes.sendAudioIcon} />
							</IconButton>
						</div>
					) : (
						<IconButton
							aria-label="showRecorder"
							component="span"
							disabled={loading}
							onClick={handleStartRecording}
						>
							<MicIcon fontSize="large" color="action" className={classes.sendMessageIcons} />
						</IconButton>
					)}

                </div>

                <DialogActions>
					<Button
                        onClick={handleClose}
                        color="secondary"
                        variant="outlined"
					>
					    {i18n.t("scheduleModal.buttons.cancel")}
					</Button>
					<Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.btnWrapper}
                        disabled={!fileToUpload}
                        onClick={handleUpload}
					>
					    {i18n.t("scheduleModal.buttons.okAdd")}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default AudioModal;