import React, { useState, useEffect, useRef } from "react"

import { toast } from "react-toastify"

import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import MainHeader from "../../components/MainHeader"
import Title from "../../components/Title"
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper"
import MainContainer from "../../components/MainContainer"
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline"
import EditIcon from "@material-ui/icons/Edit"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import api from "../../services/api"
import { CSVLink } from "react-csv"
import NewTicketModal from "../../components/NewTicketModal"
import { useHistory } from "react-router-dom"
import Papa from "papaparse"

import LeadModal from "../../components/LeadModal"
import ConfirmationModal from "../../components/ConfirmationModal"
import { i18n } from "../../translate/i18n"

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    header: {
        textAlign: "center",
        padding: theme.spacing(2),
    },
}))

const Leads = () => {
    const history = useHistory()
    const csvUploadRef = useRef()
    const classes = useStyles()
    const [openModalAddLead, setOpenModalAddLead] = useState(false)
    const [selectedLead, setSelectedLead] = useState(null)
    const [loading, setLoading] = useState(false)
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false)
    const [contactTicket, setContactTicket] = useState({})
    const [confirmModalDeleteOpen, setConfirmModalDeleteOpen] = useState(false)
    const [leads, setLeads] = useState([])
    const [deletingLead, setDeletingLead] = useState(null)

    const handleEdit = async (item) => {
        setOpenModalAddLead(true)
        setSelectedLead(item)
    }

    const handleDelete = (lead) => {
        setDeletingLead(lead)
        setConfirmModalDeleteOpen(true)
    }

    const apiDeleteItem = async (id) => {
        try {
            await api.delete(`/leads/remove/${id}`)
            getLeads()
            setDeletingLead(null)
            setConfirmModalDeleteOpen(false)
        } catch (error) {
            toast.error("Erro ao deletar lead")
        }
    }

    async function getLeads() {
        try {
            const response = await api.get("/leads")
            setLeads(response.data)
        } catch (error) {
            toast.error("Não foi possível carregar leads")
        }
    }

    const handleSendLeads = async (leads) => {
        try {
            setLoading(true)
            const response = await api.post(`/leads/import`, leads)

            if (response.status === 200) {
                toast.success("Leads importados com sucesso")
                getLeads()
            }
            csvUploadRef.current.value = ""
            setLoading(false)
        } catch (e) {
            setLoading(false)
            toast.error(
                "Ocorreu um erro ao enviar leads. Verifique o arquivo CSV"
            )
        }
    }

    const handleImportCSV = async (event) => {
        const file = event.target.files[0]
        const fileType = file.name.split(".").pop().toLowerCase()
        if (fileType !== "csv") {
            toast.error("Somente são aceitos arquivos .csv")
            return
        }
        Papa.parse(file, {
            complete: (result) => {
                const res = result.data.map((item) => {
                    if (!!(item["Nome"] && item["Telefone"])) {
                        return {
                            name: item["Nome"],
                            number: item["Telefone"].replace(/\D/g, ""),
                            email: item["Email"],
                        }
                    }

                    return {
                        name: item["NOME DO LEAD"],
                        number: item["CONTATOS DO LEAD"],
                        email: item["E-MAIL DO LEAD"],
                        referenceId: item["CÓDIGO"],
                        pipeline: item["PIPELINE"],
                        refInterest: item["REF INTERESSE"],
                        origin: item["CANAL DE ORIGEM"],
                    }
                })

                let parsedList = []

                res.forEach((item) => {
                    if (item.name && item.number && item.email) {
                        parsedList.push(item)
                    }
                })

                console.log(parsedList)

                handleSendLeads(parsedList)
            },
            header: true,
        })
    }

    useEffect(() => {
        getLeads()
    }, [])

    const handleCloseOrOpenTicket = (ticket) => {
        setNewTicketModalOpen(false)
        if (ticket !== undefined && ticket.uuid !== undefined) {
            history.push(`/tickets/${ticket.uuid}`)
        }
    }

    return (
        <MainContainer className={classes.mainContainer}>
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                initialContact={contactTicket?.contact}
                onClose={(ticket) => {
                    handleCloseOrOpenTicket(ticket)
                }}
            />

            <ConfirmationModal
                title={deletingLead && `Excluir lead ${deletingLead.name}?`}
                open={confirmModalDeleteOpen}
                onClose={() => setConfirmModalDeleteOpen(false)}
                onConfirm={() => apiDeleteItem(deletingLead.id)}
            >
                Esta ação não pode ser desfeita.
            </ConfirmationModal>

            <LeadModal
                leadId={selectedLead?.id}
                initialValues={selectedLead}
                onClose={() => {
                    setOpenModalAddLead(false)
                    setSelectedLead(null)
                }}
                onSave={() => {
                    setOpenModalAddLead(false)
                    getLeads()
                    setSelectedLead(null)
                }}
                open={openModalAddLead}
            />
            <MainHeader>
                <Title>Leads</Title>
                <MainHeaderButtonsWrapper>
                    <input
                        type="file"
                        multiple={false}
                        ref={csvUploadRef}
                        style={{ display: "none" }}
                        onChange={handleImportCSV}
                        disabled={loading}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => csvUploadRef.current?.click()}
                        disabled={loading}
                    >
                        Importar Leads
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenModalAddLead(true)}
                        disabled={loading}
                    >
                        Adicionar Lead
                    </Button>
                    <CSVLink
                        style={{ textDecoration: "none" }}
                        separator=";"
                        filename={"leads.csv"}
                        data={leads.map((lead) => ({
                            "NOME DO LEAD": lead?.name,
                            "CONTATOS DO LEAD": lead?.number,
                            "E-MAIL DO LEAD": lead?.email,
                            CÓDIGO: lead?.referenceId,
                            PIPELINE: lead?.pipeline,
                            "REF INTERESSE": lead?.refInterest,
                            "CANAL DE ORIGEM": lead?.origin,
                        }))}
                    >
                        <Button variant="contained" color="primary">
                            EXPORTAR LEDS
                        </Button>
                    </CSVLink>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Nome</TableCell>
                            <TableCell align="left">WhatsApp</TableCell>
                            <TableCell align="center">E-mail</TableCell>
                            <TableCell align="center">Filial</TableCell>
                            <TableCell align="center">Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {leads.map((lead) => (
                            <TableRow key={lead?.id}>
                                <TableCell align="center">
                                    {lead?.name}
                                </TableCell>
                                <TableCell
                                    style={{ paddingLeft: 40 }}
                                    align="left"
                                >
                                    {lead?.number}
                                </TableCell>
                                <TableCell
                                    style={{ paddingLeft: 40 }}
                                    align="left"
                                >
                                    {lead?.email}
                                </TableCell>
                                <TableCell
                                    style={{ paddingLeft: 40 }}
                                    align="left"
                                >
                                    {lead?.subsidiary?.name}
                                </TableCell>

                                <TableCell
                                    align="center"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            setContactTicket(lead)
                                            setNewTicketModalOpen(true)
                                        }}
                                    >
                                        <WhatsAppIcon />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleEdit(lead)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleDelete(lead)}
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default Leads
