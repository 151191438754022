import React, {
    useState,
    useEffect,
    useReducer,
    useContext,
    useCallback,
} from "react"
import { makeStyles } from "@material-ui/core/styles"
import api from "../../services/api"
import { AuthContext } from "../../context/Auth/AuthContext"
import Board from "react-trello"
import { toast } from "react-toastify"
import { i18n } from "../../translate/i18n"
import { useHistory } from "react-router-dom"

import { Chip } from "@material-ui/core"
import KanbanCardDescription from "../../components/KanbanCard"

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1),
    },
    button: {
        background: "#10a110",
        border: "none",
        padding: "8px",
        color: "white",
        fontWeight: "bold",
        borderRadius: "5px",
    },
    tagContainer: {
        display: "flex",
        gap: "0.3em",
        flexWrap: "wrap",
    },
    justifyBetween: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "0.5em",
        marginBottom: "0.5em",
    },
}))

const Kanban = () => {
    const classes = useStyles()
    const history = useHistory()

    const [tags, setTags] = useState([])

    const fetchTags = async () => {
        try {
            const response = await api.get("/tags/kanban")
            const fetchedTags = response.data.lista || []

            setTags(fetchedTags)

            // Fetch tickets after fetching tags
            await fetchTickets(jsonString)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchTags()
    }, [])

    const [file, setFile] = useState({
        lanes: [],
    })

    const [tickets, setTickets] = useState([])
    const { user } = useContext(AuthContext)

    const jsonString = user.queues.map((queue) => queue.UserQueue.queueId)

    const fetchTickets = async (jsonString) => {
        try {
            const { data } = await api.get("/ticket/kanban", {
                params: {
                    queueIds: JSON.stringify(jsonString),
                },
            })
            setTickets(data.tickets)
        } catch (err) {
            console.log(err)
            setTickets([])
        }
    }

    function filtrarPorTags(array) {
        return array.filter((item) => {
            // Verifica se a chave 'tags' não existe ou é um array vazio
            if (!item.hasOwnProperty("tags") || item.tags.length === 0) {
                return true
            }
            // Verifica se todas as tags possuem 'kanban: 0'
            return item.tags.every((tag) => tag?.kanban === 0)
        })
    }

    const { profile } = user

    const popularCards = (jsonString) => {
        const filteredTickets = tickets.filter(
            (ticket) => ticket.tags.length === 0
        )

        let defaultTagSum = 0
        if (filteredTickets.length) {
            filteredTickets.forEach((e) => {
                if (e.value) {
                    defaultTagSum += Number(e.value)
                }
            })
        }

        const openTickets = filtrarPorTags(tickets)

        const lanes = [
            {
                id: "lane0",
                title: `${i18n.t("tagsKanban.laneDefault")} ${
                    openTickets?.length
                }`,
                label: defaultTagSum.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                }),
                cards: openTickets.map((ticket) => {
                    return {
                        id: ticket.id.toString(),
                        label: "Ticket nº " + ticket.id.toString(),
                        description: <KanbanCardDescription ticket={ticket} />,
                        title: ticket.contact.name,
                        draggable: true,
                        href: "/tickets/" + ticket.uuid,
                    }
                }),
            },
            ...tags.map((tag) => {
                const filteredTickets = tickets.filter((ticket) => {
                    const tagIds = ticket.tags.map((tag) => tag.id)
                    return tagIds.includes(tag.id)
                })

                let tagSum = 0
                if (filteredTickets.length) {
                    filteredTickets.forEach((e) => {
                        if (e.value) {
                            tagSum += Number(e.value)
                        }
                    })
                }

                return {
                    id: tag.id.toString(),
                    title: `${tag.name} ${filteredTickets?.length}`,
                    label: tagSum.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                    }),
                    cards: filteredTickets.map((ticket) => ({
                        id: ticket.id.toString(),
                        label: "Ticket nº " + ticket.id.toString(),
                        description: <KanbanCardDescription ticket={ticket} />,
                        title: ticket.contact.name,
                        draggable: true,
                        href: "/tickets/" + ticket.uuid,
                    })),
                    style: { backgroundColor: tag.color, color: "white" },
                }
            }),
        ]

        // if (profile === "admin") {
        //     lanes.unshift({
        //         id: "lane0",
        //         title: i18n.t("tagsKanban.laneDefault"),
        //         label: defaultTagSum.toLocaleString("pt-BR", {
        //             style: "currency",
        //             currency: "BRL",
        //         }),
        //         cards: openTickets.map((ticket) => {
        //             return {
        //                 id: ticket.id.toString(),
        //                 label: "Ticket nº " + ticket.id.toString(),
        //                 description: <KanbanCardDescription ticket={ticket} />,
        //                 title: ticket.contact.name,
        //                 draggable: true,
        //                 href: "/tickets/" + ticket.uuid,
        //             }
        //         }),
        //     })
        // }

        setFile({ lanes })
    }

    const handleCardClick = (uuid) => {
        history.push("/tickets/" + uuid)
    }

    const onCardClick = (cardId) => {
        const card = tickets.filter((e) => e.id === cardId)[0]
        handleCardClick(card.uuid)
    }

    useEffect(() => {
        popularCards(jsonString)
    }, [tags, tickets])

    const handleCardMove = async (fromLaneId, toLaneId, ticketId) => {
        try {
            const arr = tickets
            const i = arr
                .map((item) => Number(item.id))
                .indexOf(Number(ticketId))
            arr[i].tags = arr[i].tags?.filter(
                (item) => Number(item.id) !== Number(fromLaneId)
            )

            await api.delete(`/ticket-tags/${ticketId}`)
            if (toLaneId !== "lane0") {
                arr[i].tags = [
                    ...arr[i].tags,
                    ...tags.filter(
                        (item) => Number(item.id) === Number(toLaneId)
                    ),
                ]
                await api.put(`/ticket-tags/${ticketId}/${toLaneId}`)
            }

            setTickets(arr)
            popularCards()
        } catch (err) {
            toast.error(
                "Ocorreu um erro ao tentar mover o ticket. Atualize a página."
            )
            console.log(err)
        }
    }

    return (
        <div className={classes.root}>
            <Board
                data={file}
                hideCardDeleteIcon
                onCardMoveAcrossLanes={handleCardMove}
                style={{
                    backgroundColor: "rgba(252, 252, 252, 0.03)",
                    height: "calc(100vh - 64px)",
                }}
                onCardClick={(cardId) => onCardClick(Number(cardId))}
            />
        </div>
    )
}

export default Kanban
