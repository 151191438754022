import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

import ContactNotesDialogListItem from "../ContactNotesDialogListItem";
import ConfirmationModal from "../ConfirmationModal";

import { toast } from "react-toastify";

import { i18n } from "../../translate/i18n";
import InputAdornment from "@material-ui/core/InputAdornment";
import ButtonWithSpinner from "../ButtonWithSpinner";

import useTicketNotes from "../../hooks/useTicketNotes";
import { Grid } from "@material-ui/core";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: "350px"
        }
    },
    list: {
        width: "100%",
        maxWidth: "350px",
        maxHeight: "200px",
        backgroundColor: theme.palette.background.paper,
        overflow: "auto"
    },
    inline: {
        width: "100%"
    }
}));

const ProductsSchema = Yup.object().shape({
    product: Yup.string().min(2, "Too Short!").required("Required"),
    value: Yup.string().min(2, "Too Short!").required("Required")
});

export function ContactProducts({ ticket }) {
    const [product, setProduct] = useState({ product: "", value: "" });
    const [loading, setLoading] = useState(false);

    const handleChange = (key, value) => {
        setProduct({ ...product, [key]: value });
    };

    useEffect(() => {
        if (ticket.product || ticket.value) {
            setProduct(ticket);
        }
    }, [ticket]);

    const handleSave = async values => {
        setLoading(true);
        try {
            const payload = {
                product: values.product,
                value: parseFloat(values.value)
            };

            await api.put(`/tickets/${ticket.id}`, { ...payload });
            toast.success("Observação adicionada com sucesso!");
        } catch (e) {
            toast.error(e);
        }
        setLoading(false);
    };

    return (
        <>
            <Formik
                initialValues={product}
                enableReinitialize={true}
                validationSchema={ProductsSchema}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        handleSave(values);
                        actions.setSubmitting(false);
                    }, 400);
                }}
            >
                {({ touched, errors, setErrors }) => (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid xs={12} item spacing={4}>
                                <Field
                                    id="outlined-basic"
                                    as={TextField}
                                    label="Produto"
                                    variant="outlined"
                                    name="product"
                                    // error={touched.note && Boolean(errors.note)}
                                    // helperText={touched.note && errors.note}
                                    fullWidth
                                    onChange={e =>
                                        handleChange("product", e.target.value)
                                    }
                                    style={{
                                        marginBottom: "20px"
                                    }}
                                />
                                <Field
                                    id="outlined-basic"
                                    as={TextField}
                                    label="Valor"
                                    variant="outlined"
                                    name="value"
                                    fullWidth
                                    type="number"
                                    // error={touched.note && Boolean(errors.note)}
                                    // helperText={touched.note && errors.note}
                                    onChange={e =>
                                        handleChange("value", e.target.value)
                                    }
                                    style={{
                                        marginBottom: "20px"
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                R$
                                            </InputAdornment>
                                        )
                                    }}
                                    labelWidth={60}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <Grid container spacing={2}>
                                    <Grid xs={6} item>
                                        <Button
                                            onClick={() => {
                                                setProduct({
                                                    value: "",
                                                    product: ""
                                                });
                                                setErrors({});
                                            }}
                                            color="primary"
                                            variant="outlined"
                                            fullWidth
                                        >
                                            Cancelar
                                        </Button>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <ButtonWithSpinner
                                            loading={loading}
                                            color="primary"
                                            type="submit"
                                            variant="contained"
                                            autoFocus
                                            fullWidth
                                        >
                                            Salvar
                                        </ButtonWithSpinner>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
}
