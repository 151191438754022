import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Chip } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    tagContainer: {
        display: "flex",
        gap: "0.3em",
        flexWrap: "wrap"
    },
    justifyBetween: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "0.5em",
        marginBottom: "0.5em"
    },
    readMoreButton: {
        background: "none",
        border: "none",
        outline: "none",
        cursor: "pointer",
        color: "#007aff",
        paddingLeft: 0,
        marginTop: "0.5em"
    }
}));

export default function KanbanCardDescription({ ticket }) {
    const classes = useStyles();
    const [isShortened, setIsShortened] = useState(true);
    const [text, setText] = useState(
        ticket.lastMessage?.length > 100
            ? `${ticket.lastMessage?.slice(0, 100)}...`
            : ticket.lastMessage
    );

    useEffect(() => {
        if (isShortened && ticket?.lastMessage.length > 100) {
            setText(`${ticket?.lastMessage.slice(0, 100)}...`);
        } else {
            setText(ticket?.lastMessage);
        }
    }, [isShortened]);

    return (
        <div>
            <p>
                {ticket.contact.number}
                <br />
                {text}
                <br />
                {ticket?.lastMessage.length > 100 && (
                    <button
                        onClick={e => {
                            e.stopPropagation();
                            setIsShortened(!isShortened);
                        }}
                        className={classes.readMoreButton}
                        type="button"
                    >
                        {isShortened ? "Ler mais" : "Ler menos"}
                    </button>
                )}
            </p>

            <div className={classes.justifyBetween}>
                {ticket.product && (
                    <b
                        style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden"
                        }}
                    >
                        {ticket.product}
                    </b>
                )}
                {ticket.value && (
                    <span>
                        {Number(ticket.value)?.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                        })}
                    </span>
                )}
            </div>
            <div className={classes.tagContainer}>
                {ticket.tags?.map(tag => {
                    if (tag.kanban === 0) {
                        return (
                            <Chip
                                key={tag.id}
                                variant="outlined"
                                style={{
                                    backgroundColor: tag.color || "#eee",
                                    textShadow: "1px 1px 1px #000",
                                    color: "white"
                                }}
                                label={tag.name}
                                size="small"
                            />
                        );
                    }
                })}
            </div>
        </div>
    );
}
