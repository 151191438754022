import React, { useContext, useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Typography from "@material-ui/core/Typography"
import EditIcon from "@material-ui/icons/Edit"
import { i18n } from "../../translate/i18n"
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline"
import { makeStyles } from "@material-ui/core/styles"
import api from "../../services/api"
import {
    Avatar,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core"
import { Can } from "../Can"
import TableRowSkeleton from "../TableRowSkeleton"
import { AuthContext } from "../../context/Auth/AuthContext"
import { toast } from "react-toastify"
import toastError from "../../errors/toastError"

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}))

const RepeatedContactsModal = ({ open, onClose, repeatedContacts }) => {
    const classes = useStyles()
    const [contacts, setContacts] = useState([])
    const [loading, setLoading] = useState(false)
    const { user } = useContext(AuthContext)

    async function getData() {
        setLoading(true)
        const numbers = repeatedContacts.map((item) => item.number)
        const { data } = await api.get(
            `/contacts/repeated?numbers=${JSON.stringify(numbers)}`
        )
        setContacts(data.contacts)
    }

    const handleDeleteContact = async (contactId) => {
        setLoading(true)
        try {
            await api.delete(`/contacts/${contactId}`)
            getData()
            toast.success(i18n.t("contacts.toasts.deleted"))
        } catch (err) {
            toastError(err)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (repeatedContacts.length) getData()
        setLoading(false)
    }, [repeatedContacts])

    return (
        <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle id="confirm-dialog">
                Excluir contatos repetidos
            </DialogTitle>
            <DialogContent dividers>
                <Typography
                    variant="body1"
                    color="error"
                    style={{ marginBottom: 20 }}
                >
                    Ao excluir um contato, o ticket relacionado a ele também
                    será excluído.
                </Typography>
                <Paper className={classes.mainPaper} variant="outlined">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox" />
                                <TableCell>
                                    {i18n.t("contacts.table.name")}
                                </TableCell>
                                <TableCell align="center">
                                    {i18n.t("contacts.table.whatsapp")}
                                </TableCell>
                                <TableCell align="center">
                                    {i18n.t("contacts.table.email")}
                                </TableCell>
                                <TableCell align="center">
                                    {i18n.t("contacts.table.actions")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <>
                                {contacts.map((contact) => (
                                    <TableRow key={contact.id}>
                                        <TableCell style={{ paddingRight: 0 }}>
                                            {
                                                <Avatar
                                                    src={contact.profilePicUrl}
                                                />
                                            }
                                        </TableCell>
                                        <TableCell>{contact.name}</TableCell>
                                        <TableCell align="center">
                                            {contact.number}
                                        </TableCell>
                                        <TableCell align="center">
                                            {contact.email}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Can
                                                role={user.profile}
                                                perform="contacts-page:deleteContact"
                                                yes={() => (
                                                    <IconButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            handleDeleteContact(
                                                                contact.id
                                                            )
                                                        }}
                                                    >
                                                        <DeleteOutlineIcon />
                                                    </IconButton>
                                                )}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {loading && (
                                    <TableRowSkeleton avatar columns={3} />
                                )}
                            </>
                        </TableBody>
                    </Table>
                </Paper>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => onClose(false)}
                    color="default"
                >
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RepeatedContactsModal
