import React, { useState, useEffect, useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import Chip from "@material-ui/core/Chip"
import StepLabel from "@material-ui/core/StepLabel"
import Typography from "@material-ui/core/Typography"
import { Button, IconButton, StepContent, TextField } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import UploadIcon from "@material-ui/icons/AttachFile"
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline"
import SaveIcon from "@material-ui/icons/Save"
import EditIcon from "@material-ui/icons/Edit"
import api from "../../services/api"
import toastError from "../../errors/toastError"
import { toast } from "react-toastify"
import { head } from "lodash"
import ModalSelectAudio from "../ModalSelectAudio"
import { getFile } from "../../services/firebase"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        //height: 400,
        [theme.breakpoints.down("sm")]: {
            maxHeight: "20vh",
        },
    },
    button: {
        marginRight: theme.spacing(1),
    },
    input: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    addButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
}))

export function QueueOptionStepper({ queueId, options, updateOptions }) {
    const classes = useStyles()
    const attachmentFile = useRef(null)

    const [activeOption, setActiveOption] = useState(-1)
    const [modalSendRecordedAudio, setModalSendRecordedAudio] = useState(false)

    const handleOption = (index) => async () => {
        setActiveOption(index)
        const option = options[index]

        if (option !== undefined && option.id !== undefined) {
            try {
                const { data } = await api.request({
                    url: "/queue-options",
                    method: "GET",
                    params: { queueId, parentId: option.id },
                })
                const optionList = data.map((option) => {
                    return {
                        ...option,
                        children: [],
                        edition: false,
                    }
                })
                option.children = optionList
                updateOptions()
            } catch (e) {
                toastError(e)
            }
        }
    }

    const handleSave = async (option) => {
        try {
            if (option.id) {
                await api.request({
                    url: `/queue-options/${option.id}`,
                    method: "PUT",
                    data: option,
                })
            } else {
                const { data } = await api.request({
                    url: `/queue-options`,
                    method: "POST",
                    data: option,
                })
                option.id = data.id
            }
            option.edition = false
            updateOptions()
        } catch (e) {
            toastError(e)
        }
    }

    const handleEdition = (index) => {
        options[index].edition = !options[index].edition
        updateOptions()
    }

    const handleDeleteOption = async (index) => {
        const option = options[index]
        if (option !== undefined && option.id !== undefined) {
            try {
                await api.request({
                    url: `/queue-options/${option.id}`,
                    method: "DELETE",
                })
            } catch (e) {
                toastError(e)
            }
        }
        options.splice(index, 1)
        options.forEach(async (option, order) => {
            option.option = order + 1
            await handleSave(option)
        })
        updateOptions()
    }

    const handleOptionChangeTitle = (event, index) => {
        options[index].title = event.target.value
        updateOptions()
    }

    const handleOptionChangeMessage = (event, index) => {
        options[index].message = event.target.value
        updateOptions()
    }

    const renderTitle = (index) => {
        const option = options[index]
        if (option.edition) {
            return (
                <>
                    <TextField
                        value={option.title}
                        onChange={(event) =>
                            handleOptionChangeTitle(event, index)
                        }
                        size="small"
                        className={classes.input}
                        placeholder="Título da opção"
                    />
                    {option.edition && (
                        <>
                            <IconButton
                                color="primary"
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={() => handleSave(option)}
                            >
                                <SaveIcon />
                            </IconButton>
                            <IconButton
                                variant="outlined"
                                color="secondary"
                                size="small"
                                className={classes.button}
                                onClick={() => handleDeleteOption(index)}
                            >
                                <DeleteOutlineIcon />
                            </IconButton>
                        </>
                    )}
                </>
            )
        }
        return (
            <>
                <Typography>
                    {option.title !== "" ? option.title : "Título não definido"}
                    <IconButton
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={() => handleEdition(index)}
                    >
                        <EditIcon />
                    </IconButton>
                </Typography>
            </>
        )
    }

    const renderMessage = (index) => {
        const option = options[index]
        if (option.edition) {
            return (
                <>
                    <TextField
                        style={{ width: "100%" }}
                        multiline
                        value={option.message}
                        onChange={(event) =>
                            handleOptionChangeMessage(event, index)
                        }
                        size="small"
                        className={classes.input}
                        placeholder="Digite o texto da opção"
                    />
                </>
            )
        }
        return (
            <>
                <Typography onClick={() => handleEdition(index)}>
                    {option.message}
                </Typography>
            </>
        )
    }

    const handleAddOption = (index) => {
        const optionNumber = options[index].children.length + 1
        options[index].children.push({
            title: "",
            message: "",
            edition: false,
            option: optionNumber,
            queueId,
            parentId: options[index].id,
            children: [],
        })
        updateOptions()
    }

    const handleDeleteMedia = async (option, index) => {
        try {
            await api.delete(`/queue-options/${option.id}/media-upload`)
            toast.success("Arquivo excluido com sucesso")
            options[index].mediaName = null
            options[index].mediaPath = null
            updateOptions()
        } catch (error) {
            toastError("Erro ao fazer upload de arquivo ", error)
        }
    }

    const [selectedOption, setSelectedOption] = useState()

    const renderStep = (option, index) => {
        const handleUploadFile = async (attachment) => {
            try {
                if (attachment) {
                    const formData = new FormData()
                    formData.append("file", attachment)
                    const response = await api.post(
                        `/queue-options/${selectedOption}/media-upload`,
                        formData
                    )
                    options[index].mediaName = response.data.mediaName
                    options[index].mediaPath = response.data.mediaPath
                    toast.success("Arquivo enviado com sucesso!")
                    setSelectedOption()
                }

                updateOptions()
                attachmentFile.current.value = ""
            } catch (error) {
                attachmentFile.current.value = ""
                toastError("Erro ao fazer upload de arquivo ", error)
            }
        }

        const handleAttachmentFile = async (e) => {
            await handleUploadFile(head(e.target.files))
        }

        const getMediaPath = (filename) => {
            return filename
        }

        const handleAttachAudio = async (path) => {
            const downloadLink = await getFile(path)
            fetch(downloadLink)
                .then((res) => res.blob())
                .then(async (blob) => {
                    const fi = new File([blob], `${Date.now()}.mp3`, {
                        type: "audio/mpeg",
                    })

                    await handleUploadFile(fi)
                    setModalSendRecordedAudio(false)
                })
        }

        return (
            <Step key={index}>
                <StepLabel
                    style={{ cursor: "pointer" }}
                    onClick={handleOption(index)}
                >
                    {renderTitle(index)}
                </StepLabel>

                <div style={{ display: "none" }}>
                    <input
                        type="file"
                        accept=".png,.jpg,.jpeg"
                        ref={attachmentFile}
                        onChange={handleAttachmentFile}
                    />
                </div>

                <StepContent>
                    {renderMessage(index)}

                    {option.id !== undefined && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Button
                                color="primary"
                                size="small"
                                onClick={() => handleAddOption(index)}
                                startIcon={<AddIcon />}
                                variant="outlined"
                                className={classes.addButton}
                            >
                                Adicionar
                            </Button>

                            {option.mediaPath ? (
                                <Chip
                                    icon={<UploadIcon />}
                                    label={option.mediaName}
                                    onClick={() => {
                                        const url = getMediaPath(
                                            option.mediaPath
                                        )
                                        const a = document.createElement("a")
                                        a.href = url
                                        a.download = url.split("/").pop()
                                        document.body.appendChild(a)
                                        a.setAttribute("download", "true")
                                        a.setAttribute("target", "_blank")
                                        a.click()
                                        document.body.removeChild(a)
                                    }}
                                    onDelete={() =>
                                        handleDeleteMedia(option, index)
                                    }
                                />
                            ) : (
                                <>
                                    <Button
                                        color="secondary"
                                        size="small"
                                        onClick={() => {
                                            attachmentFile.current.click()
                                            setSelectedOption(option.id)
                                        }}
                                        startIcon={<UploadIcon />}
                                        variant="outlined"
                                        className={classes.addButton}
                                    >
                                        Anexar arquivo
                                    </Button>

                                    <ModalSelectAudio
                                        open={modalSendRecordedAudio}
                                        onSelecteAudio={(audio) => {
                                            handleAttachAudio(audio)
                                        }}
                                        handleClose={() =>
                                            setModalSendRecordedAudio(false)
                                        }
                                    />

                                    <Button
                                        color="primary"
                                        size="small"
                                        onClick={() => {
                                            setSelectedOption(option.id)
                                            setModalSendRecordedAudio(true)
                                        }}
                                        startIcon={<UploadIcon />}
                                        variant="outlined"
                                        className={classes.addButton}
                                        style={{ marginLeft: "6px" }}
                                    >
                                        Anexar áudio
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                    <QueueOptionStepper
                        queueId={queueId}
                        options={option.children}
                        updateOptions={updateOptions}
                    />
                </StepContent>
            </Step>
        )
    }

    const renderStepper = () => {
        return (
            <Stepper
                style={{ marginBottom: 0, paddingBottom: 0 }}
                nonLinear
                activeStep={activeOption}
                orientation="vertical"
            >
                {options.map((option, index) => renderStep(option, index))}
            </Stepper>
        )
    }

    return renderStepper()
}

export function QueueOptions({ queueId }) {
    const classes = useStyles()
    const [options, setOptions] = useState([])

    const fetchOptions = async () => {
        try {
            const { data } = await api.request({
                url: "/queue-options",
                method: "GET",
                params: { queueId, parentId: -1 },
            })
            const optionList = data.map((option) => {
                return {
                    ...option,
                    children: [],
                    edition: false,
                }
            })
            setOptions(optionList)
        } catch (e) {
            toastError(e)
        }
    }

    useEffect(() => {
        if (queueId) {
            fetchOptions()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderStepper = () => {
        if (options.length > 0) {
            return (
                <QueueOptionStepper
                    queueId={queueId}
                    updateOptions={updateOptions}
                    options={options}
                />
            )
        }
    }

    const updateOptions = () => {
        setOptions([...options])
        // fetchOptions()
    }

    const addOption = () => {
        const newOption = {
            title: "",
            message: "",
            edition: false,
            option: options.length + 1,
            queueId,
            parentId: null,
            children: [],
        }
        setOptions([...options, newOption])
    }

    return (
        <div className={classes.root}>
            <br />
            <Typography>
                Opções
                <Button
                    color="primary"
                    size="small"
                    onClick={addOption}
                    startIcon={<AddIcon />}
                    style={{ marginLeft: 10 }}
                    variant="outlined"
                >
                    Adicionar
                </Button>
            </Typography>
            {renderStepper()}
        </div>
    )
}
