import { Chip, Paper, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import { isArray, isString } from "lodash";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import api from "../../services/api";

import { MenuItem, FormControl, Select } from "@material-ui/core";

export function TagsContainer({ ticket }) {
    const [tags, setTags] = useState([]);
    const [kanban, setKanban] = useState([]);
    const [selecteds, setSelecteds] = useState([]);
    const isMounted = useRef(true);

    const [selectedKanban, setSelectedKanban] = useState(0);

    const handleSelect = async event => {
        const { value } = event.target;

        await Promise.all([
            await api.delete(`/ticket-tags/${ticket.id}`),
            await api.put(`/ticket-tags/${ticket.id}/${value}`)
        ])
            .then(() => {
                toast.success("Kanban atualizado com sucesso");
                setSelectedKanban(value);
            })
            .catch(e => toastError(e));
    };

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            loadTags().then(() => {
                if (Array.isArray(ticket.tags)) {
                    const selectedTagTicketListId = ticket.tags.map(e => {
                        return e.id;
                    });
                    const selectedTagTicketList = tags.filter(e =>
                        selectedTagTicketListId.includes(e.id)
                    );

                    setSelecteds(selectedTagTicketList);

                    kanban.filter(e => {
                        if (selectedTagTicketListId.includes(e.id)) {
                            setSelectedKanban(e.id);
                        }
                    });
                } else {
                    setSelecteds([]);
                }
            });
        }
    }, [ticket]);

    const createTag = async data => {
        try {
            const { data: responseData } = await api.post(`/tags`, data);
            return responseData;
        } catch (err) {
            toastError(err);
        }
    };

    const loadTags = async () => {
        await Promise.all([
            await api.get(`/tags/list`),
            await api.get(`/tags/kanban`)
        ])
            .then(([tags, kanban]) => {
                setTags(tags.data);
                setKanban(kanban.data.lista);
            })
            .catch(err => toastError(err));
    };

    const syncTags = async data => {
        try {
            const { data: responseData } = await api.post(`/tags/sync`, data);
            return responseData;
        } catch (err) {
            toastError(err);
        }
    };

    const onChange = async (value, reason) => {
        let optionsChanged = [];
        if (reason === "create-option") {
            if (isArray(value)) {
                for (let item of value) {
                    if (isString(item)) {
                        const newTag = await createTag({ name: item });
                        optionsChanged.push(newTag);
                    } else {
                        optionsChanged.push(item);
                    }
                }
            }
            await loadTags();
        } else {
            optionsChanged = value;
        }
        setSelecteds(optionsChanged);
        await syncTags({ ticketId: ticket.id, tags: optionsChanged });
    };

    return (
        <Paper style={{ padding: 12, display: "flex", gap: "6px" }}>
            <Autocomplete
                multiple
                size="small"
                options={tags}
                value={selecteds}
                freeSolo
                onChange={(e, v, r) => onChange(v, r)}
                getOptionLabel={option => option.name}
                fullWidth
                style={{ width: "80%" }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                        return (
                            <Chip
                                variant="outlined"
                                style={{
                                    backgroundColor: option.color || "#eee",
                                    textShadow: "1px 1px 1px #000",
                                    color: "white"
                                }}
                                label={option.name}
                                {...getTagProps({ index })}
                                size="small"
                            />
                        );
                    })
                }
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Tags"
                    />
                )}
                PaperComponent={({ children }) => (
                    <Paper style={{ width: 400, marginLeft: 12 }}>
                        {children}
                    </Paper>
                )}
            />

            <FormControl size="small" style={{ width: "20%" }}>
                <Select
                    value={selectedKanban}
                    label="Kanban"
                    onChange={handleSelect}
                    fullWidth
                    variant="outlined"
                    placeholder="Kanban"
                >
                    {kanban.map(item => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Paper>
    );
}
