import React from "react"

import { toast } from "react-toastify"
import { i18n } from "../translate/i18n"
import { isString } from "lodash"

import config from "../config/config.json"

const toastError = (err) => {
    const errorMsg = err.response?.data?.error
    if (errorMsg) {
        if (errorMsg === "Nenhum pagamento ativo") {
            // eslint-disable-next-line no-restricted-globals
            if (window.location.pathname !== "/financeiro") {
                // eslint-disable-next-line no-restricted-globals
                window.location.href = "/financeiro"
            }

            toast.error(
                `Assine um plano para continuar utilizando a ${config.system.name}.`,
                {
                    toastId: errorMsg,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                }
            )
        } else if (i18n.exists(`backendErrors.${errorMsg}`)) {
            toast.error(i18n.t(`backendErrors.${errorMsg}`), {
                toastId: errorMsg,
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return
        } else {
            toast.error(errorMsg, {
                toastId: errorMsg,
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return
        }
    } else if (isString(err)) {
        toast.error(err)
        return
    } else {
        toast.error("An error occurred!")
        return
    }
}

export default toastError
