export function formatWhatsNumber(numero) {
    if(!numero) return
     // Remover caracteres não numéricos
     const numeroLimpo = numero.replace(/\D/g, '');

     // Aplicar a máscara
     const codigoArea = numeroLimpo.substring(0, 2);
     const digitoComplementar = numeroLimpo.substring(2, 3);
     const parteCentral = numeroLimpo.substring(2, 7);
     const parteFinal = numeroLimpo.substring(7, 11);

     // Retornar número formatado
     return `(${codigoArea}) ${digitoComplementar} ${parteCentral}-${parteFinal}`;
}

