import React, { useEffect } from "react"
import api from "../../services/api"

export default function Subsidiaries() {
    const getData = async () => {
        const res = await api.get("/subsidiaries")
        console.log("SUBSIDIARIES: ", res.data)
    }
    useEffect(() => {
        getData()
    }, [])

    return (
        <div>
            <h2>subsidiaries</h2>
        </div>
    )
}
