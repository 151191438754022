import { initializeApp } from "firebase/app"
import {
    getStorage,
    ref,
    uploadBytes,
    getDownloadURL,
    deleteObject,
} from "firebase/storage"

import firebaseConfig from "../config/firebase.config.json"
import { loginFirebaseUser } from "./firebaseAuthService"

const app = initializeApp(firebaseConfig)
const storage = getStorage(app)

export const UploadPathTypes = {
    SHEDULES: "schedules",
}

export const uploadFile = (file, fileName) => {
    const fileRef = ref(storage, fileName)
    uploadBytes(fileRef, file)
        .then((snapshot) => {
            console.log(snapshot)
        })
        .catch((error) => console.error(error))
}

export const getFile = async (fileName) => {
    await loginFirebaseUser()
    const storageRef = ref(storage, fileName)
    return await getDownloadURL(storageRef).then((downloadUrl) => downloadUrl)
}

export const removeFile = async (fileName) => {
    const storageRef = ref(storage, fileName)
    return await deleteObject(storageRef)
}
